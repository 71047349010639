<template>
  <div>
    <el-row>
      <el-button
        v-throttle
        type="primary"
        icon="el-icon-circle-plus-outline"
        size="medium"
        @click="edit('add', '')"
        >添加</el-button
      >
    </el-row>
    <el-row style="margin-top: 20px">
      <el-table
        v-loading="loading"
        border
        ref="roleTable"
        :data="roleList"
        tooltip-effect="dark"
        :header-cell-style="{
          'text-align': 'center',
          backgroundColor: '#dfe6ec',
        }"
        :cell-style="{ 'text-align': 'center' }"
        style="width: 100%"
      >
        <el-table-column
          prop="name"
          :width="160"
          label="名称"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="code"
          :width="300"
          label="角色编码"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="description"
          label="描述"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="操作" min-width="60px" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-button
              v-throttle
              size="mini"
              type="success"
              icon="el-icon-edit"
              @click="edit('edit', scope.row)"
              >编辑</el-button
            >
            <el-button
              v-throttle
              size="mini"
              type="primary"
              icon
              @click="roleUrlManage(scope.row)"
              >角色权限管理</el-button
            >
            <!-- <el-button v-throttle  size="mini" type="primary" icon @click="roleApiManage(scope.row)">角色接口管理</el-button> -->
            <el-button
              v-throttle
              size="mini"
              type="danger"
              icon="el-icon-delete"
              @click="remove(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-row>

    <el-row style="margin-top: 20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.pageIndex"
        :page-sizes="[5, 8, 10]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.totalSize"
      ></el-pagination>
    </el-row>

    <el-row>
      <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose"
      >
        <el-form
          :model="roleForm"
          :rules="rules"
          ref="roleForm"
          label-width="80px"
        >
          <el-form-item label="名称" prop="name">
            <el-input
              v-model="roleForm.name"
              autocomplete="off"
              class="inputWidth"
              clearable
              placeholder="请输入名称"
              maxlength="6"
              show-word-limit
            ></el-input>
          </el-form-item>

          <el-form-item label="角色编码" prop="code">
            <el-input
              v-model="roleForm.code"
              autocomplete="off"
              class="inputWidth"
              clearable
              placeholder="请输入角色编码"
              maxlength="20"
              show-word-limit
            ></el-input>
          </el-form-item>

          <el-form-item label="描述" prop="description">
            <el-input
              type="textarea"
              v-model="roleForm.description"
              autocomplete="off"
              class="inputWidth"
              clearable
              placeholder="请输入描述"
              :rows="3"
              maxlength="30"
              show-word-limit
            ></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button
            v-throttle
            type="primary"
            @click="save('roleForm')"
            :loading="confirmLoading"
          >
            {{ confirmLoading ? confirmLoadingButtonText : confirmButtonText }}
          </el-button>
          <el-button v-throttle @click="cancel('roleForm')">取 消</el-button>
        </div>
      </el-dialog>
    </el-row>

    <el-row>
      <el-dialog
        :title="urlTitle"
        :visible.sync="urlDialogVisible"
        width="30%"
        :before-close="urlHandleClose"
      >
        <div>
          <el-row style="margin-bottom: 5px">
            <el-link type="primary" :underline="false" style="font-size: 17px"
              >当前角色：{{ roleUrl.name }}</el-link
            >
          </el-row>
          <el-row>
            <el-col :span="18">
              <el-input
                prefix-icon="el-icon-search"
                placeholder="输入关键字进行过滤"
                v-model="urlFilter"
              ></el-input>
              <el-tree
                node-key="sysAuthUrlId"
                :data="urlTreeList"
                default-expand-all
                :props="urlProps"
                show-checkbox
                highlight-current
                :filter-node-method="filterNode"
                ref="urlTree"
                accordion
                :default-checked-keys="urlCheckedTreeList"
                @check="urlHandleCheck"
              ></el-tree>
            </el-col>
          </el-row>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button
            v-throttle
            type="primary"
            @click="roleUrlSave"
            :loading="confirmLoading"
            >确认</el-button
          >
          <el-button v-throttle @click="urlCancel('roleForm')">取 消</el-button>
        </div>
      </el-dialog>
    </el-row>

    <el-row>
      <el-dialog
        :title="apiTitle"
        :visible.sync="apiDialogVisible"
        width="30%"
        :before-close="apiHandleClose"
      >
        <div>
          <el-row style="margin-bottom: 5px">
            <el-link type="primary" :underline="false" style="font-size: 17px"
              >当前角色：{{ roleApi.name }}</el-link
            >
          </el-row>
          <el-row>
            <el-col :span="18">
              <el-input
                prefix-icon="el-icon-search"
                placeholder="输入关键字进行过滤"
                v-model="apiFilter"
              ></el-input>
              <el-tree
                node-key="sysAuthApiId"
                :data="apiTreeList"
                default-expand-all
                :props="apiProps"
                show-checkbox
                highlight-current
                :filter-node-method="filterNode"
                ref="apiTree"
                accordion
                :default-checked-keys="apiCheckedTreeList"
                @check="apiHandleCheck"
              ></el-tree>
            </el-col>
          </el-row>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button
            v-throttle
            type="primary"
            @click="roleApiSave"
            :loading="confirmLoading"
            >确认</el-button
          >
          <el-button v-throttle @click="apiCancel('roleForm')">取 消</el-button>
        </div>
      </el-dialog>
    </el-row>
  </div>
</template>

<script>
import { getApiList } from '@/api/user/roleApiManage.js'
import { getUrlListApi } from '@/api/user/roleUrlManage.js'
import {
  findRolePageApi,
  removeRoleApi,
  getRoleByIdApi,
  addRoleApi,
  updateRoleApi,
  updateRoleUrlApi,
  updateRoleApiApi,
} from '@/api/user/roleManage.js'
import { isEmpty } from '@/utils/util.js'
export default {
  name: 'roleManage',
  components: {},
  data() {
    const validateCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('请输入名称编码'))
      }
      if (!/^(?![0-9]*$)(?![a-zA-Z]*$)[a-zA-Z0-9]{4,20}$/.test(value)) {
        callback(new Error('编码必须为4-20位字母和数字组合'))
      } else {
        callback()
      }
    }
    return {
      //开启遮罩层
      loading: true,
      roleList: [],
      //分页
      page: {
        pageIndex: 1,
        pageSize: 10,
        totalSize: 0,
      },
      title: '',
      text: '',
      dialogVisible: false,
      roleForm: {
        name: '',
        code: '',
        description: '',
      },
      updateRole: {},
      roleUrl: {},
      roleApi: {},
      //validator: validateCode
      rules: {
        name: [
          {
            required: true,
            message: '请输入名称',
            trigger: ['blur', 'change'],
          },
        ],
        code: [
          {
            required: true,
            message: '请输入角色编码',
            trigger: ['blur', 'change'],
          },
        ],
        description: [
          {
            required: true,
            message: '请输入描述',
            trigger: ['blur', 'change'],
          },
        ],
      },
      //确认按钮是否加载
      confirmLoading: false,
      confirmLoadingButtonText: '保存中...',
      confirmButtonText: '确认',
      urlDialogVisible: false,
      urlTitle: '',
      urlFilter: '',
      urlTreeList: [],
      urlProps: {
        label: 'name',
        children: 'childrenAuthUrl',
      },
      urlCheckedTreeList: [],
      submitUrlTreeList: [],
      apiFilter: '',
      apiTitle: '',
      apiDialogVisible: false,
      apiTreeList: [],
      apiProps: {
        label: 'name',
        children: 'childrenAuthApi',
      },
      apiCheckedTreeList: [],
      submitApiTreeList: [],
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      findRolePageApi(this.page.pageIndex, this.page.pageSize)
        .then((res) => {
          this.loading = false
          if (res.success) {
            this.roleList = res.data
            this.page.totalSize = res.total
          } else {
            this.msgError(res.msg)
          }
        })
        .catch(() => {})
    },
    edit(text, row) {
      this.roleForm = {}
      this.dialogVisible = true
      this.text = text
      if (text == 'add') {
        this.title = '添加角色'
      } else if (text == 'edit') {
        this.title = '编辑角色'
        this.updateRole = row
        getRoleByIdApi(row.sysAuthRoleId)
          .then((res) => {
            if (res.success) {
              this.roleForm = res.data
            } else {
              this.msgError(res.msg)
            }
          })
          .catch(() => {})
      }
    },
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const loading = this.globalLoading('正在保存...')
          if (this.text == 'add') {
            addRoleApi(this.roleForm)
              .then((res) => {
                loading.close()
                this.dialogVisible = false
                if (res.success) {
                  this.msgSuccess('添加成功')
                  this.init()
                } else {
                  this.msgError(res.msg)
                }
              })
              .catch(() => {
                loading.close()
                this.dialogVisible = false
              })
          } else if (this.text == 'edit') {
            updateRoleApi(this.roleForm)
              .then((res) => {
                loading.close()
                this.dialogVisible = false
                if (res.success) {
                  this.msgSuccess('修改成功')
                  this.init()
                } else {
                  this.msgError(res.msg)
                }
              })
              .catch(() => {
                loading.close()
                this.dialogVisible = false
              })
          }
        } else {
          return false
        }
      })
    },
    remove(row) {
      if (isEmpty(row)) {
        return
      }
      const idList = []
      idList.push(row.sysAuthRoleId)
      this.globalMessageBox(
        '确认要删除该角色吗?',
        '提示',
        '确定',
        '取消',
        'warning'
      )
        .then(() => {
          const loading = this.globalLoading('正在删除...')
          removeRoleApi(idList)
            .then((res) => {
              loading.close()
              if (res.success) {
                this.msgSuccess('删除成功')
                this.page.pageIndex = 1
                this.init()
              } else {
                this.msgError(res.msg)
              }
            })
            .catch(() => {
              loading.close()
            })
        })
        .catch(() => {})
    },
    roleUrlManage(row) {
      this.roleUrl = row
      this.urlDialogVisible = true
      this.urlTitle = '角色路由管理'
      this.initUrlTree(row)
    },
    initUrlTree(row) {
      const sysAuthUrlId = 0
      getUrlListApi(sysAuthUrlId)
        .then((res) => {
          if (res.success) {
            this.urlTreeList = []
            this.urlTreeList = res.data
            getRoleByIdApi(row.sysAuthRoleId)
              .then((res) => {
                if (res.success) {
                  this.urlCheckedTreeList = []
                  this.urlCheckedTreeList = res.data.checkUrlIdList
                } else {
                  this.msgError(res.msg)
                }
              })
              .catch(() => {})
          } else {
            this.msgError(res.msg)
          }
        })
        .catch(() => {})
    },
    roleUrlSave() {
      this.globalMessageBox(
        '确认保存已勾选角色吗？',
        '提示',
        '确认',
        '取消',
        'warning'
      )
        .then(() => {
          const loading = this.globalLoading('正在保存...')
          updateRoleUrlApi(
            this.roleUrl.sysAuthRoleId,
            this.submitUrlTreeList
          ).then((res) => {
            loading.close()
            this.urlCheckedTreeList = []
            this.urlDialogVisible = false
            if (res.success) {
              this.msgSuccess('保存成功')
            } else {
              this.msgError(res.msg)
            }
          })
        })
        .catch(() => {
          loading.close()
          this.urlCheckedTreeList = []
          this.urlDialogVisible = false
        })
    },
    roleApiManage(row) {
      this.roleApi = row
      this.apiDialogVisible = true
      this.apiText = '角色接口管理'
      this.initApiTree(row)
    },
    initApiTree(row) {
      const sysAuthApiId = 0
      getApiList(sysAuthApiId)
        .then((res) => {
          if (res.success) {
            this.apiTreeList = []
            this.apiTreeList = res.data
            getRoleByIdApi(row.sysAuthRoleId)
              .then((res) => {
                this.apiCheckedTreeList = []
                this.apiCheckedTreeList = res.data.checkApiIdList
              })
              .catch(() => {})
          } else {
            this.msgError(res.msg)
          }
        })
        .catch(() => {})
    },
    roleApiSave() {
      this.globalMessageBox(
        '确认保存已勾选角色吗？',
        '提示',
        '确认',
        '取消',
        'warning'
      )
        .then(() => {
          const loading = this.globalLoading('正在保存...')
          updateRoleApiApi(
            this.roleApi.sysAuthRoleId,
            this.submitApiTreeList
          ).then((res) => {
            loading.close()
            this.apiCheckedTreeList = []
            this.apiDialogVisible = false
            if (res.success) {
              this.msgSuccess('保存成功')
            } else {
              this.msgError(res.msg)
            }
          })
        })
        .catch(() => {
          loading.close()
          this.apiCheckedTreeList = []
          this.apiDialogVisible = false
        })
    },
    //currentPage 改变时会触发;如第一页切换到第二页
    handleCurrentChange(val) {
      this.loading = true
      this.page.pageIndex = val
      this.init()
    },
    //pageSize 改变时会触发;如每页10条切换到每页15条
    handleSizeChange(val) {
      this.loading = true
      this.page.pageSize = val
      this.init()
    },
    //弹出框右上角×触发
    handleClose() {
      this.dialogVisible = false
      this.$refs['roleForm'].resetFields()
    },
    cancel(formName) {
      this.dialogVisible = false
      this.$refs[formName].resetFields()
    },
    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    urlHandleCheck(
      checkedNodes,
      checkedKeys,
      halfCheckedNodes,
      halfCheckedKeys
    ) {
      this.submitUrlTreeList = []
      this.submitUrlTreeList = this.$refs.urlTree.getCheckedKeys()
    },
    urlHandleClose() {
      this.urlCheckedTreeList = []
      this.urlDialogVisible = false
    },
    urlCancel() {
      this.urlCheckedTreeList = []
      this.urlDialogVisible = false
    },
    apiHandleCheck(
      checkedNodes,
      checkedKeys,
      halfCheckedNodes,
      halfCheckedKeys
    ) {
      this.submitApiTreeList = []
      this.submitApiTreeList = this.$refs.apiTree.getCheckedKeys()
    },
    apiHandleClose() {
      this.apiDialogVisible = false
    },
    apiCancel() {
      this.apiDialogVisible = false
    },
  },
  watch: {
    urlFilter(val) {
      this.$refs.urlTree.filter(val)
    },
    apiFilter(val) {
      this.$refs.apiTree.filter(val)
    },
  },
}
</script>

<style>
.inputWidth {
  /* width: 400px; */
  /* max-width: 250px; */
}
</style>
